@import "general/newTypography";
@import "general/colors";
.new-blue-area {
    padding: 2.5em 0;
    background: rgb(0, 90, 205);
    background: linear-gradient(90deg, rgba(0, 90, 205, 1) 0%, rgba(0, 181, 221, 1) 100%);
    .text {
        text-align: center;
        color: white;
        font-family: $ubuntu-typography;
        font-size: 30px;
        font-weight: normal;
        .bold {
            font-weight: bolder;
        }
        @media screen and (max-width: 1000px) {
            font-size: 28px;
        }
        @media screen and (max-width: 550px) {
            font-size: 25px;
        }
        .titlebold{
            text-align: center;
            color: white;
            font-family: $ubuntu-typography;
            font-size: 38px;
            font-weight: bolder;
        }
    }
    .cloud-providers-area {
        display: flex;
        gap: 2em;
        justify-content: center;
        margin-top: 1em;
        flex-wrap: wrap;
        @media screen and (max-width: 650px) {
            gap: 1em;
        }
        .cloud-provider-card {
            width: calc(100% / 3);
            max-width: 289px;
            height: 60px;
            border-radius: 15px;
            background: white;
            padding: 0.9em 0.5em;
            border-radius: 15px;
            @media screen and (max-width: 950px) {
                width: calc(100% / 2);
            }
            @media screen and (max-width: 650px) {
                width: 100%;
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    &.style-2 {
        background: $new-blue;
    }
}
